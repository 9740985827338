<template>
  <b-overlay
      variant="white"
      :show="formLoader"
      spinner-variant="primary"
      blur="1"
      opacity=".75"
      rounded="sm"
  >
    <b-card :title="title">
      <validation-observer ref="FormValidation">
        <b-form @submit.prevent="submit">
          <div class="row">
            <div class="col-4">
              <b-form-group
                  label="Job Order"
              >
                <b-form-input
                    disabled
                    :value="form.job_order"
                    placeholder="Job Order"
                />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                  label="Offer Reference"
              >
                <validation-provider #default="{ errors }" name="offer reference" rules="required">
                  <v-select
                      label="serial_number"
                      placeholder="Select Offer"
                      :clearable="false"
                      :loading="offersListLoader"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                      :options="offersList"
                      :reduce="option=>option.id"
                      v-model="form.offer_id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Project Name">
                <b-form-input
                    placeholder="Project Name"
                    disabled
                    :value="projectName"
                />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                  label="Start Date"
              >
                <b-form-input
                    disabled
                    :value="form.created_at"
                    placeholder="Start Date"
                />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Location">
                <validation-provider #default="{ errors }" name="location" rules="required">
                  <b-input-group>
                    <b-form-input
                        v-model="form.location"
                        :state="errors.length > 0 ? false : null"
                        placeholder="25.355775, 55.628001"
                    />
                    <b-input-group-append>
                      <b-button
                          :href="'https://www.google.com/maps/place/%D9%85%D8%B5%D9%86%D8%B9+%D8%A7%D9%84%D8%A8%D9%8A%D8%AA+%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85%D9%8A+%D9%84%D9%84%D8%A3%D9%84%D9%85%D9%86%D9%8A%D9%88%D9%85+%D9%88%D8%A7%D9%84%D8%B2%D8%AC%D8%A7%D8%AC%E2%80%AD/@25.3558963,55.6258442,17z/data=!3m1!4b1!4m12!1m6!3m5!1s0x3ef5f35969c6be0d:0xf114506bc5e9ca52!2z2YXYtdmG2Lkg2KfZhNio2YrYqiDYp9mE2LnYp9mE2YXZiiDZhNmE2KPZhNmF2YbZitmI2YUg2YjYp9mE2LLYrNin2Kw!8m2!3d25.3558915!4d55.6280329!3m4!1s0x3ef5f35969c6be0d:0xf114506bc5e9ca52!8m2!3d25.3558915!4d55.6280329'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          target="_blank"
                          class="btn-icon"
                          @click="form.location = ''"
                      >
                        <feather-icon
                            icon="MapPinIcon"
                            v-b-tooltip.hover
                            title="Google Maps"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                  label="Project Status"
              >
                <validation-provider #default="{ errors }" name="project status" rules="required">
                  <v-select
                      label="name"
                      placeholder="Select Status"
                      :clearable="false"
                      class="status-selector"
                      :loading="statusListLoader"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                      :options="statusList"
                      :reduce="option=>option.name"
                      v-model="form.status"
                  >
                    <template #option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                      <span class="d-flex option-style" :style="{backgroundColor:color, color:'#fff'}"> {{
                          name
                        }}</span>
                    </template>

                    <template #selected-option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                      <span class="d-flex pl-2 pr-2" :style="{backgroundColor:color, color:'#fff'}"> {{ name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                  label="Project Owner"
              >
                <validation-provider #default="{ errors }" name="project owner" rules="required">
                  <v-select
                      placeholder="Select Owner"
                      label="first_name"
                      :clearable="false"
                      :loading="ownersListLoader"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                      :options="ownersList"
                      :reduce="option=>option.id"
                      v-model="form.owner_id"
                  >
                    <template #option="{ first_name, last_name }">
                      <b-avatar
                          size="26"
                          :text="avatarText(first_name + ' ' + last_name)"
                      />
                      <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                    </template>

                    <template #selected-option="{ first_name, last_name }">
                      <b-avatar
                          size="20"
                          :text="avatarText(first_name + ' ' + last_name)"
                      />
                      <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                  label="Sales Agent"
              >
                <validation-provider #default="{ errors }" name="sales agent" rules="required">
                  <v-select
                      placeholder="Select Sales Agent"
                      label="first_name"
                      class="assignee-selector"
                      :clearable="false"
                      :loading="salesListLoader"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                      :options="salesList"
                      :reduce="option => option.id"
                      v-model="form.sales_agent_id"
                  >
                    <template #option="{ first_name, last_name }">
                      <b-avatar
                          size="26"
                          :text="avatarText(first_name + ' ' + last_name)"
                      />
                      <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                    </template>

                    <template #selected-option="{ first_name, last_name }">
                      <b-avatar
                          size="20"
                          :text="avatarText(first_name + ' ' + last_name)"
                      />
                      <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Google Drive URL">
                <validation-provider
                    #default="{ errors }"
                    name="google drive URL"
                    rules="required|url"
                >

                  <b-input-group>
                    <b-form-input
                        v-model="form.google_drive_link"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://drive.google.com/123"
                    />
                    <b-input-group-append>
                      <b-button
                          :href="'https://drive.google.com/'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          target="_blank"
                          class="btn-icon"
                      >
                        <feather-icon
                            icon="LinkIcon"
                            v-b-tooltip.hover
                            title="Google Drive"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button variant="primary"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        class="float-right"
              >
                {{ action }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import ProjectService from "@/services/ProjectService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Ripple from "vue-ripple-directive";
import {VBTooltip} from "bootstrap-vue";

export default {
  name: "ProjectForm",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      required: true
    },
    action: {
      required: true
    },
    formLoader: {
      type: Boolean,
      required: true
    },
    projectId: {
      default: null,
    },
    form: {
      type: Object,
      default() {
        return {
          job_order: null,
          offer_id: null,
          name: null,
          created_at: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
          location: null,
          status: null,
          owner_id: null,
          sales_agent_id: null,
          google_drive_link: null,
        }
      }
    }
  },
  computed: {
    projectName() {
      return this.offersList.find(option => {
        return option.id === this.form.offer_id
      })?.project_name
    },
  },
  data() {
    return {
      projectService: new ProjectService(),
      offersList: [],
      offersListLoader: true,
      statusList: [],
      statusListLoader: true,
      ownersList: [],
      ownersListLoader: true,
      salesList: [],
      salesListLoader: true
    }
  },
  methods: {
    getOfferRefList() {
      this.offersListLoader = true;
      this.projectService.getOffersList()
          .then(res => {
            console.log(res)
            this.offersList = res.data;
          })
          .catch(err => {
          })
          .finally(() => {
            this.offersListLoader = false;
          });
    },
    getProjectStatusList() {
      this.statusListLoader = true;
      this.projectService.getProjectStatus()
          .then(res => {
            this.statusList = res.data;
          })
          .finally(() => {
            this.statusListLoader = false;
          });
    },
    getOwnersUsersOptions() {
      this.ownersListLoader = true;
      this.projectService.getUsersOptions({'roles[]': 'Project_Manager'})
          .then(res => {
            this.ownersList = res.data;
          })
          .catch(err => {
          })
          .finally(() => {
            this.ownersListLoader = false;
          });
    },
    getSalesUsersOptions() {
      this.salesListLoader = true;
      this.projectService.getUsersOptions({'roles[]': 'Sales_Agent'})
          .then(res => {
            this.salesList = res.data;
          })
          .catch(err => {
          })
          .finally(() => {
            this.salesListLoader = false;
          });
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    submit() {
      this.$refs.FormValidation.validate().then(success => {
        console.log(this.form)
        if (success) {
          const emitData = {
            "offer_id": this.form.offer_id,
            "location": this.form.location,
            "status": this.form.status,
            "owner_id": this.form.owner_id,
            "sales_agent_id": this.form.sales_agent_id,
            "google_drive_link": this.form.google_drive_link
          }
          console.warn('success')
          console.log('emitData', emitData)
          this.$emit('submit', emitData)
        }
      })
    },
  },
  mounted() {
    this.getOfferRefList()
    this.getProjectStatusList()
    this.getOwnersUsersOptions()
    this.getSalesUsersOptions()
  }
}
</script>

<style scoped lang="scss">
.option-style {
  padding: 5px;
  //border-radius: 0.358rem;
  justify-content: center;
}

::v-deep .status-selector .vs__dropdown-option {
  padding: 0;
  margin: 2px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","show":_vm.formLoader,"spinner-variant":"primary","blur":"1","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"title":_vm.title}},[_c('validation-observer',{ref:"FormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Job Order"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.form.job_order,"placeholder":"Job Order"}})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Offer Reference"}},[_c('validation-provider',{attrs:{"name":"offer reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"label":"serial_number","placeholder":"Select Offer","clearable":false,"loading":_vm.offersListLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.offersList,"reduce":function (option){ return option.id; }},model:{value:(_vm.form.offer_id),callback:function ($$v) {_vm.$set(_vm.form, "offer_id", $$v)},expression:"form.offer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Project Name"}},[_c('b-form-input',{attrs:{"placeholder":"Project Name","disabled":"","value":_vm.projectName}})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Start Date"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.form.created_at,"placeholder":"Start Date"}})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Location"}},[_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"25.355775, 55.628001"},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"href":'https://www.google.com/maps/place/%D9%85%D8%B5%D9%86%D8%B9+%D8%A7%D9%84%D8%A8%D9%8A%D8%AA+%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85%D9%8A+%D9%84%D9%84%D8%A3%D9%84%D9%85%D9%86%D9%8A%D9%88%D9%85+%D9%88%D8%A7%D9%84%D8%B2%D8%AC%D8%A7%D8%AC%E2%80%AD/@25.3558963,55.6258442,17z/data=!3m1!4b1!4m12!1m6!3m5!1s0x3ef5f35969c6be0d:0xf114506bc5e9ca52!2z2YXYtdmG2Lkg2KfZhNio2YrYqiDYp9mE2LnYp9mE2YXZiiDZhNmE2KPZhNmF2YbZitmI2YUg2YjYp9mE2LLYrNin2Kw!8m2!3d25.3558915!4d55.6280329!3m4!1s0x3ef5f35969c6be0d:0xf114506bc5e9ca52!8m2!3d25.3558915!4d55.6280329',"variant":"primary","target":"_blank"},on:{"click":function($event){_vm.form.location = ''}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"MapPinIcon","title":"Google Maps"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Project Status"}},[_c('validation-provider',{attrs:{"name":"project status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"status-selector",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"label":"name","placeholder":"Select Status","clearable":false,"loading":_vm.statusListLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusList,"reduce":function (option){ return option.name; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex option-style",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex pl-2 pr-2",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Project Owner"}},[_c('validation-provider',{attrs:{"name":"project owner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"placeholder":"Select Owner","label":"first_name","clearable":false,"loading":_vm.ownersListLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ownersList,"reduce":function (option){ return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"26","text":_vm.avatarText(first_name + ' ' + last_name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}},{key:"selected-option",fn:function(ref){
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"20","text":_vm.avatarText(first_name + ' ' + last_name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}}],null,true),model:{value:(_vm.form.owner_id),callback:function ($$v) {_vm.$set(_vm.form, "owner_id", $$v)},expression:"form.owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Sales Agent"}},[_c('validation-provider',{attrs:{"name":"sales agent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"assignee-selector",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"placeholder":"Select Sales Agent","label":"first_name","clearable":false,"loading":_vm.salesListLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.salesList,"reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"26","text":_vm.avatarText(first_name + ' ' + last_name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}},{key:"selected-option",fn:function(ref){
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"20","text":_vm.avatarText(first_name + ' ' + last_name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}}],null,true),model:{value:(_vm.form.sales_agent_id),callback:function ($$v) {_vm.$set(_vm.form, "sales_agent_id", $$v)},expression:"form.sales_agent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',{attrs:{"label":"Google Drive URL"}},[_c('validation-provider',{attrs:{"name":"google drive URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"https://drive.google.com/123"},model:{value:(_vm.form.google_drive_link),callback:function ($$v) {_vm.$set(_vm.form, "google_drive_link", $$v)},expression:"form.google_drive_link"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"href":'https://drive.google.com/',"variant":"primary","target":"_blank"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"LinkIcon","title":"Google Drive"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.action)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }